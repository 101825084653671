<template>
    <div class="w-0">
        LIVE CHAT<!-- Your component's HTML structure here -->
    </div>
</template>

<script>
export default {
    mounted() {
        this.loadHelpScoutScript();
    },
    methods: {
        loadHelpScoutScript() {
            // Create the first script tag
            const script1 = document.createElement("script");
            script1.type = "text/javascript";
            script1.async = true;
            script1.src = "https://beacon-v2.helpscout.net";

            // Create the second script tag for initialization
            const script2 = document.createElement("script");
            script2.type = "text/javascript";
            script2.text = `!function(e,t,n){
                          function a(){
                            var e=t.getElementsByTagName("script")[0],
                                n=t.createElement("script");
                            n.type="text/javascript",
                            n.async=!0,
                            n.src="https://beacon-v2.helpscout.net",
                            e.parentNode.insertBefore(n,e)
                          }
                          if(e.Beacon=n=function(t,n,a){
                            e.Beacon.readyQueue.push({method:t,options:n,data:a})
                          },
                          n.readyQueue=[],"complete"===t.readyState)return a();
                          e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)
                        }(window,document,window.Beacon||function(){});
                        window.Beacon('init', 'babe0f46-33c4-415a-9bf2-b0a49d912c78');`;

            // Append scripts to the body or specific location
            document.body.appendChild(script1);
            document.body.appendChild(script2);
        },
    },
};
</script>
