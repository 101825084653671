<script setup></script>
<template>
    <svg
        width="18"
        height="12"
        viewBox="0 0 18 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.9998 1C18.0093 0.93032 18.0093 0.85968 17.9998 0.79C17.9911 0.731289 17.9743 0.674074 17.9498 0.62C17.9234 0.571127 17.8933 0.524327 17.8598 0.48C17.8218 0.416753 17.7746 0.359469 17.7198 0.31L17.5998 0.24C17.5421 0.196957 17.478 0.163215 17.4098 0.14H17.2098C17.1489 0.0809951 17.0777 0.0335575 16.9998 0H11.9998C11.4475 0 10.9998 0.447715 10.9998 1C10.9998 1.55228 11.4475 2 11.9998 2H14.8298L10.8298 6.71L6.50981 4.14C6.0871 3.88858 5.54432 3.98187 5.22981 4.36L0.22981 10.36C0.0597511 10.5641 -0.022116 10.8274 0.00227313 11.092C0.0266622 11.3565 0.155303 11.6005 0.35981 11.77C0.539722 11.9191 0.766158 12.0005 0.99981 12C1.29724 12.0005 1.57945 11.8685 1.76981 11.64L6.21981 6.3L10.4898 8.86C10.9079 9.10799 11.4438 9.01938 11.7598 8.65L15.9998 3.7V6C15.9998 6.55228 16.4475 7 16.9998 7C17.5521 7 17.9998 6.55228 17.9998 6V1Z"
            class="fill-[#7D8DA6] group-focus:fill-[#0516B2] group-[&.active]:fill-[#0516B2]"
        />
    </svg>
</template>
