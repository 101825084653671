<template>
    <div class="relative">
        <div
            @click="toggle"
            class="flex items-center justify-center h-10 gap-0.5 cursor-pointer languageBox"
        >
            <span class="flex text-sm font-semibold text-primary">{{
                currentLanguage.toUpperCase()
            }}</span>
            <div class="arrow [&.active]:rotate-180">
                <Icon type="arrow-dropdown" />
            </div>
        </div>

        <!-- Language Switcher -->
        <div
            class="absolute bg-[#eaebf7] w-max rounded-xl min-w-40 overflow-hidden top-[calc(100%+16px)] left-1/2 z-30 shadow -translate-x-1/2 group-[&amp;.active]/dropdown:block p-1"
            v-if="menuopen"
        >
            <ul class="flex flex-col gap-1">
                <li
                    v-for="(lg, key) in langs"
                    :key="key"
                    class="flex rounded-lg overflow-hidden items-center gap-2 cursor-pointer group/li p-2 hover:bg-[#919EAB29]"
                    @click="handleChangeLanguage(key)"
                >
                    <div class="max-w-7 shrink-0">
                        <img :src="lg.flag" :alt="key" />
                    </div>
                    <h6
                        class="group-hover/li:[text-shadow:_1px_0px_0_rgb(0_0_0_/_100%)] text-sm text-inherit"
                    >
                        {{ $t(lg.name) }}
                    </h6>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { useI18n } from "vue-i18n";
import Icon from "@/Icons/perks-icons.vue";
export default {
    components: {
        Icon,
    },
    data() {
        return {
            langs: {
                en: {
                    name: "English",
                    flag: "/images/flag.png",
                },
                fr: {
                    name: "French",
                    flag: "/images/french.png",
                },
                de: {
                    name: "German",
                    flag: "/images/german.png",
                },
                it: {
                    name: "Italian",
                    flag: "/images/ic-it.png",
                },
                es: {
                    name: "Spanish",
                    flag: "/images/ic-es.png",
                },
                pt: {
                    name: "Portuguese",
                    flag: "/images/ic-pt.png",
                },
                el: {
                    name: "Greek",
                    flag: "/images/ic-gr.png",
                },
                nl: {
                    name: "Dutch",
                    flag: "/images/ic-nl.png",
                },
                ro: {
                    name: "Romanian",
                    flag: "/images/ic-ro.png",
                },
            },
            menuopen: false,
        };
    },

    setup() {
        const { t, locale } = useI18n();
        const changeLanguage = async function (lang) {
            try {
                await axios.post("/lang", { locale: lang });

                this.menuopen = false;
                locale.value = lang;
                localStorage.setItem("locale", lang);
                location.reload();
            } catch (error) {
                console.error(error);
            }
        };
        return {
            t,
            currentLanguage: [
                "en",
                "de",
                "fr",
                "it",
                "es",
                "pt",
                "el",
                "nl",
                "ro",
            ].includes(locale.value)
                ? locale
                : "en",
            changeLanguage,
        };
    },
    methods: {
        toggle(event) {
            let arrow = event.target
                .closest(".languageBox")
                .querySelector(".arrow");
            arrow.classList.toggle("active");
            this.menuopen = !this.menuopen;
        },
        handleChangeLanguage(lang) {
            this.menuopen = false;
            this.changeLanguage(lang);
        },
    },
};
</script>
