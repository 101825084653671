<template>
    <div class="flex-auto w-full max-w-[320px] md:max-w-[420px] mx-auto text-sm bg-white shadow">
        <header class="flex w-full p-5 bg-white">
            <h2 class="text-lg font-bold">{{ $t('Notifications') }}</h2>
            <div class="flex items-start justify-end w-full">
                <GenericButton
                    type="button"
                    theme="primary-text"
                    text="Mark as read"
                    iconPosition="left"
                    @click="markAllAsRead">
                    <svg width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                              d="M12.6936 0.277581C13.0205 0.60156 13.0229 1.12919 12.6989 1.45608L10.0089 4.17025C9.6849 4.49714 9.15727 4.4995 8.83038 4.17552C8.50349 3.85154 8.50113 3.32391 8.82511 2.99702L11.5151 0.282852C11.8391 -0.0440374 12.3667 -0.046397 12.6936 0.277581ZM16.862 0.280176C17.1875 0.605594 17.1875 1.13323 16.8621 1.45869L9.79212 8.52952C9.63584 8.68582 9.42387 8.77363 9.20285 8.77363C8.98183 8.77364 8.76986 8.68584 8.61357 8.52956L5.07774 4.99372C4.7523 4.66828 4.7523 4.14065 5.07774 3.81521C5.40317 3.48977 5.93081 3.48977 6.25625 3.81521L9.20279 6.76175L15.6835 0.280245C16.009 -0.0452108 16.5366 -0.0452419 16.862 0.280176ZM0.91107 3.81521C1.23651 3.48977 1.76414 3.48977 2.08958 3.81521L5.62541 7.35104C5.95085 7.67648 5.95085 8.20412 5.62541 8.52956C5.29998 8.85499 4.77234 8.85499 4.4469 8.52956L0.91107 4.99372C0.585633 4.66828 0.585633 4.14065 0.91107 3.81521Z"
                              fill="#0516B2"/>
                    </svg>
                </GenericButton>
            </div>
        </header>

        <!-- Tabs for filtering notifications -->
        <hr>
        <div class="flex gap-10 px-5 text-sm border-b">
            <button
                class="inline-block py-3 font-bold border-b-2 border-transparent rounded-t-lg text-disabled dark:text-blue-500 dark:border-blue-500"
                :class="{ ' !text-black  !border-black ': activeTab === 'all', 'text-black': activeTab !== 'all' }"
                @click="activeTab = 'all'">
                {{ $t('All') }} <span v-if="notifications.length > 0"
                                      class="px-1.5 py-0.5 text-xs text-white bg-black rounded-md ml-2">{{
                    unreadNotifications.length
                }}</span>
            </button>

            <button
                class="inline-block py-3 font-bold border-b-2 border-transparent rounded-t-lg text-disabled dark:text-blue-500 dark:border-blue-500"
                :class="{ '!text-black  !border-black ': activeTab === 'unread', 'text-black': activeTab !== 'unread' }"
                @click="activeTab = 'unread'">
                {{ $t('Unread') }} <span v-if="unreadNotifications.length > 0"
                                         class="text-[#006C9C] bg-[#b0f3ff] rounded-md px-1.5 py-0.5 ml-2 ">{{
                    unreadNotifications.length
                }}</span>
            </button>
        </div>

        <!-- Display notifications based on active tab -->
        <div v-if="activeTab === 'all'">
            <div v-for="notification in notifications" :key="notification.id"
                 class="flex items-start gap-2 p-5 border-b border-dashed">

                <NotificationItem
                    :notification="notification"
                    @handle-toggle="handleToggle"
                    @preview-file="previewFile"
                />

            </div>
        </div>

        <!-- Unread notifications -->
        <div v-if="activeTab === 'unread'">
            <div v-for="notification in unreadNotifications" :key="notification.id"
                 class="flex items-start gap-2 p-5 border-b border-dashed">

                <NotificationItem
                    :notification="notification"
                    @handle-toggle="handleToggle"
                    @preview-file="previewFile"
                />

            </div>
        </div>

        <div v-if="notifications.length === 0" class="py-5 text-center text-gray-500">
            {{ $t('You haven\'t notifications yet') }}
        </div>
        <OvnToast ref="toast"></OvnToast>
        <PreviewModal ref="previewModal"/>
    </div>
</template>
<script>
import Avatar from '@/Components/Avatar.vue';
import GenericButton from '@/Components/GenericButton.vue';
import PreviewModal from "@/Components/PreviewContent/PreviewModal.vue";
import OvnToast from "@/Components/Toast.vue";
import Icon from '@/Icons/jobs-icons.vue';

import {Link, useForm} from '@inertiajs/vue3';
import axios from 'axios';
import NotificationItem from "@/Layouts/Partials/NotificationItem.vue";

export default {
    data() {
        return {
            notifications: [],
            activeTab: 'all', // Default tab
            form: useForm({
                receiver_id: '',
                status: '',
                notification_id: '',
                friend_request_id: ''
            }),
        };
    },
    components: {
        NotificationItem,
        Avatar,
        GenericButton,
        OvnToast, Icon, Link, PreviewModal
    },
    mounted() {
        this.fetchNotifications();
    },
    computed: {
        unreadNotifications() {
            return this.notifications.filter(notification => !notification.read_at);
        }
    },
    methods: {
        async fetchNotifications() {
            try {
                const response = await axios.get(route('notifications.index'));
                this.notifications = response.data;
                for (const i in this.notifications) {
                    this.notifications[i].data = JSON.parse(this.notifications[i].data);
                }
            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        },
        async markAllAsRead() {
            try {
                await axios.post(route('notifications.markAllAsRead'));
                this.fetchNotifications(); // Refresh the list after marking as read
            } catch (error) {
                console.error('Error marking notifications as read:', error);
            }
        },
        accepted(data) {
            this.form.receiver_id = data?.data?.sender?.id;
            this.form.status = 'accepted';
            this.form.notification_id = data?.id;
            this.form.friend_request_id = data?.notifiable?.id;

            this.form.post(route('friends.requests.send'), {
                onSuccess: page => {
                    this.$refs.toast.show('Friend request accepted successfully!', 'success');
                    this.fetchNotifications();
                },
            });
        },
        decline(data) {
            this.form.receiver_id = data?.data?.sender?.id;
            this.form.status = 'rejected';
            this.form.notification_id = data?.id;
            this.form.friend_request_id = data?.notifiable?.id;
            this.form.post(route('friends.requests.send'), {
                onSuccess: page => {
                    this.$refs.toast.show('Friend request declined successfully!', 'success');
                    this.fetchNotifications();
                },
            });
        },
        handleToggle() {
            this.fetchNotifications();
            this.$emit('handleToggle');
        },
        previewFile(data) {
            this.$refs.previewModal.previewContent(data.src, data.type, data.attachmentId);
        }
    }
};
</script>
